import React, {useContext, useEffect} from 'react';
import {Context} from '../AppContext';
import useForm from '../hooks/use-form';
import styled from 'styled-components';
import {Button} from '../Widgets';
import User from '../Models/User';
import formatValidator from '../Utils/formatValidator';
import {ErrUser, errorHandler} from '../errors';
import {message} from 'antd';
import {Fragment} from 'react';

const {
  isNotEmpty,
  isLetter,
  isMobileNumber,
  isLetterOrNumeric,
} = formatValidator;

const LABELS = User.labels;

export default function UserForm({record: _record, onUpdate}) {
  const {renderInput, renderText, record} = useForm({record: _record});
  const app = useContext(Context);
  const edit = !!record.id;

  const valid = () => {
    if (!isNotEmpty(record.username)) {
      throw new ErrUser('使用者名稱不能為空');
    }
    if (!isLetterOrNumeric(record.username)) {
      throw new ErrUser('使用者名稱必須是英文數字組成');
    }
    if (!edit && !isNotEmpty(record.password)) {
      throw new ErrUser('密碼不能為空');
    }
    if (!edit && record.password !== record.confirmPassword) {
      throw new ErrUser('密碼和確認密碼不一樣');
    }
    if (edit && !isMobileNumber(record.phone)) {
      throw new ErrUser('手機格式不正確');
    }
  };

  const submit = async () => {
    const action = edit ? '編輯' : '新增';
    app.actions.setModalLoading(true);
    try {
      valid(record);

      let params = {};
      if (edit) {
        let {fullname, phone} = record;

        params = {
          data: {
            fullname,
            phone,
          },
        };

        await app.actions.editUser(record.username, params);
      } else {
        params = {
          username: record.username,
          password: record.password,
        };
        await app.actions.addUser(params);
      }

      onUpdate();
      app.actions.setModal();

      message.success(action + '成功');
    } catch (ex) {
      errorHandler(ex, action + '錯誤');
      if ((ex?.detail?.msg || '').indexOf('username exists') >= 0) {
        message.error('使用者已存在');
      }
    }
    app.actions.setModalLoading(false);
  };

  return (
    <Wrapper>
      <h3>客戶資訊</h3>
      <div className="content">
        {/* {renderText(LABELS['id'], 'id')} */}
        {!edit
          ? renderInput(LABELS['username'], 'username')
          : renderText(LABELS['username'], 'username')}
        {!edit ? (
          <Fragment>
            {renderInput(LABELS['password'], 'password', {
              extraProps: {
                type: 'password',
              },
            })}
            {renderInput(LABELS['confirmPassword'], 'confirmPassword', {
              extraProps: {
                type: 'password',
              },
            })}
          </Fragment>
        ) : (
          <Fragment>
            {renderInput(LABELS['fullname'], 'fullname', {
              extraProps: {
                placeholder: '中文姓名',
              },
            })}
            {renderInput(LABELS['phone'], 'phone')}
          </Fragment>
        )}
      </div>
      <div className="footer">
        <Button onClick={submit}>確認</Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > h3 {
    padding: 20px 30px;
  }

  & > .content {
    flex: 1;
    padding: 30px;
  }

  & > .footer {
    border-top: solid 1px #ddd;
    padding: 20px 30px;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
