import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Table, Button, Modal, Input} from 'antd';
import {Context} from '../../AppContext';
import User from '../../Models/User';
import UserForm from '../../Forms/UserForm';

const labels = User.labels;
const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function UserPage(props) {
  const app = useContext(Context);
  const [records, setRecords] = useState(null);
  const [filters, _setFilters] = useState({
    ...PAGINATION_INIT,
  });
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState();

  const columns = [
    {
      title: labels.username,
      key: 'username',
      render: (record) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            app.actions.setModal(
              <UserForm record={record} onUpdate={() => getRecords(search)} />,
            );
          }}>
          {record.username}
        </a>
      ),
    },
    {
      title: labels.fullname,
      key: 'fullname',
      dataIndex: 'fullname',
    },
    {
      title: labels.phone,
      key: 'phone',
      dataIndex: 'phone',
    },
  ];

  const getRecords = useCallback(
    async (search = '') => {
      app.actions.setLoading(true);
      try {
        const params = {
          query: {
            username: {$regex: search},
          },
          paging: {
            offset: (filters.current - 1) * filters.pageSize,
            limit: filters.pageSize,
          },
          sorting: ['-created'],
        };
        let resp = await app.actions.getUsers(params);
        resp = resp.map((u) => ({...u, ...u?.data}));
        setRecords(resp);
        setTotal(resp.length);
      } catch (ex) {
        console.warn(ex);
      }
      app.actions.setLoading(false);
    },
    [app.actions, filters],
  );

  function setFilters(obj) {
    _setFilters((prev) => ({
      ...prev,
      ...PAGINATION_INIT,
      ...obj,
    }));
  }

  useEffect(() => {
    getRecords();

    return () => setRecords([]);
  }, [getRecords]);

  return (
    <Wrapper>
      <div className="action-bar">
        {/* <Input
          value={search}
          placeholder="搜尋姓名"
          style={{width: 250}}
          onChange={(e) => setSearch(e.target.value)}
        /> */}
        <Button type="primary" onClick={() => getRecords(search)}>
          刷新
        </Button>
        <div style={{flex: 1}} />
        {/* <Button type="primary">匯出清單</Button> */}
        <Button
          type="primary"
          onClick={() => {
            app.actions.setModal(
              <UserForm
                record={new User()}
                onUpdate={() => getRecords(search)}
              />,
            );
          }}>
          ＋
        </Button>
      </div>

      {records && (
        <Table
          columns={columns}
          dataSource={records}
          rowKey="id"
          pagination={{
            pageSize: filters.pageSize,
            current: filters.current,
            total,
          }}
          onChange={(pagination) =>
            setFilters({
              pageSize: pagination.pageSize,
              current: pagination.current,
            })
          }
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > button {
      margin-left: 10px;
    }
  }
`;
